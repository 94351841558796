.container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 99;
}

.hiddenButton {
  width: 100%;
  height: 100%;
  cursor: inherit;
  position: absolute;
}

.modal {
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  width: 100%;
  min-height: 415px;
  overflow-y: scroll;
  font-family: 'Inter Regular', sans-serif;
  z-index: 1000;
}

.animationEaseIn {
  animation: easeInAnimation 0.5s;
}

.animationEaseOut {
  animation: easeOutAnimation 0.5s;
}

@media (min-width: 1024px) {
  .animationEaseIn {
    animation: easeInAnimationDesktop 0.5s;
  }
  .animationEaseOut {
    animation: easeOutAnimationDesktop 0.5s;
  }
}

@keyframes easeInAnimationDesktop {
  from {
    margin-bottom: -100%;
  }
  to {
    margin-bottom: 0px;
  }
}

@keyframes easeOutAnimationDesktop {
  from {
    margin-bottom: 0;
  }
  to {
    margin-bottom: -100%;
  }
}

@keyframes easeInAnimation {
  from {
    margin-bottom: -415px;
  }
  to {
    margin-bottom: 0px;
  }
}

@keyframes easeOutAnimation {
  from {
    margin-bottom: 0;
  }
  to {
    margin-bottom: -415px;
  }
}

.header {
  position: relative;
  padding: 24px;
  border-bottom-color: #e0e0e0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.title {
  color: #212121;
  font-family: 'Inter Regular', sans-serif;
  font-size: 14px;
  letter-spacing: 0.0025em;
  line-height: 20px;
}

.box {
  padding: 24px;
  border-bottom-color: #e0e0e0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.content {
  padding: 16px 24px;
  font-family: 'Inter Regular', sans-serif;
  font-size: 14px;
  letter-spacing: 0.0025em;
  line-height: 20px;
}

.content p {
  color: #212121;
}

.content ul {
  color: #616161;
  padding: 16px 40px;
}

.content ul li {
  list-style: disc;
}

.buttonGray {
  color: #616161 !important;
}

.titleContent {
  color: #e61e4d;
  font-family: 'Inter Regular', sans-serif;
  font-size: 14px;
  line-height: 20px;
}
.shoppingContainer {
  display: flex;
  align-items: center;
}

.shoppingContainer img {
  margin-right: 8px;
}

@media (min-width: 1024px) {
  .container {
    position: fixed;
    align-items: center;
  }

  .modal {
    border-radius: 16px;
    min-width: 480px;
    max-width: 480px;
    overflow: hidden;
    height: auto;
  }
}
