.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.proof {
  margin: 34px 0 19px 0;
  display: flex;
}

.proof img:first-child {
  margin-right: 8px;
}

.proof img:nth-child(3) {
  margin-right: 18px;
}

.proof img:nth-child(8) {
  margin-right: 18px;
}

.proof img {
  margin-right: 12px;
}

.stick {
  width: 1px;
  height: 24px;
  background-color: #bdbdbd;
  margin-right: 8px;
}

.rate {
  font-family: 'Inter Bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0125em;
}

.disclaimer {
  font-family: 'Inter Regular', sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.004em;
}

@media (min-width: 1024px) {
  .proof img:first-child {
    margin-right: 8px;
  }

  .proof img:nth-child(3) {
    margin-right: 11px;
  }

  .proof img:nth-child(8) {
    margin-right: 11px;
  }

  .proof img {
    margin-right: 11px;
  }
}
