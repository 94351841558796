.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  height: 64px;
  width: 100%;
  padding: 0 20px;
  position: absolute;
}

.back {
  font-family: 'Inter Regular', sans-serif;
  color: #e61e4d;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0125em;
}

.totalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-transform: capitalize;
}

@media (min-width: 601px) {
  .header {
    padding: 0 108px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back {
    margin-left: 12px;
  }
}
